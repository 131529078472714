@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Abel";
  src: url("./assets/fonts/Abel-Regular.ttf");
}

@layer base {
  html {
    font-family: Abel;
  }
}

html {
  scroll-behavior: smooth;
}

input,
.form-input,
select,
textarea,
button,
.form-control,
.input-field:focus,
.input-field:active {
  outline: 0px !important;
  -webkit-appearance: none !important;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none !important;
}

select.list1 option.option2 {
  background-color: #ffffff !important;
}

.privacy-list {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.talent-hunters {
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  z-index: 10;
  transform: rotate(180deg);
}

.talent-hunters-inner {
  transform: rotate(-180deg);
}

.testify {
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  z-index: 10;
  transform: rotateX(180deg);
  height: 511px;
}

.testify-inner {
  transform: rotateX(-180deg);
}

.blue-bg-gradient {
  background: linear-gradient(109.19deg, #243677 -7.1%, #011048 63.98%);
}

.testimonial-content {
  margin: 10px auto;
  padding: 0 20px;
  padding-bottom: 10px;
  position: relative;
  /* max-width: 600px; */
}

.carousel-root {
  width: 64% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  color: black;
  height: 100%;
}

.myCarousel {
  margin-top: 0;
  width: 74%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 286px;
}

.carousel .control-dots {
  padding-left: 0 !important;
  outline: 0;
  bottom: 0% !important;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #567bff !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 30px !important;
  position: absolute;
  top: 40% !important;
  border-radius: 50%;
  border: 1px solid #fff !important;
}

.carousel .control-next.control-arrow:before,
.carousel .control-prev.control-arrow:before {
  margin-bottom: 11px !important;
}

.carousel .control-dots {
  margin: 20px 0 0 !important;
}

.why-work-1 {
  border: 50px solid rgba(111, 126, 202, 0.5);
  border-radius: 94.7522px 94.7522px 94.7522px 0px;
}

.why-work-2 {
  border: 50px solid rgba(189, 189, 189, 0.5);
  border-radius: 72.094px 0px;
}

.why-work-3 {
  border: 50px solid rgba(49, 151, 89, 0.5);
  border-radius: 94.7522px 94.7522px 94.7522px 0px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.discovery_ {
  height: 154px;
  width: 154px;
  border-radius: 50%;
  background: linear-gradient(131.48deg, #243677 15.12%, #03124b 83.03%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem;
  margin: 1.2rem auto;
}

.placeholder-text::placeholder {
  opacity: 0.3;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  color: #909091;
}

.testimony-name-img {
  align-items: center;
  height: 100%;
}

.ant-checkbox .ant-checkbox-inner {
  outline: 1px !important;
  border: 1px solid #243677 !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #243677;
  border-color: #243677;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 100% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }

  .myCarousel {
    /* background: #fafafa; */
    margin-top: 0%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 369px;
  }
}

@media only screen and (min-width: 240px) and (max-width: 767px) {
  .talent-hunters-inner {
    padding-top: 100px;
    padding-bottom: 40px;
  }

  .testify {
    height: 400px;
  }

  .discovery_ {
    height: 124px;
    width: 124px;
    padding: 1rem;
    margin: 1rem auto;
  }

  .why-work-1 {
    border: 30px solid rgba(111, 126, 202, 0.5);
    border-radius: 94.7522px 94.7522px 94.7522px 0px;
  }

  .why-work-2 {
    border: 30px solid rgba(189, 189, 189, 0.5);
    border-radius: 72.094px 0px;
  }

  .why-work-3 {
    border: 30px solid rgba(49, 151, 89, 0.5);
    border-radius: 94.7522px 94.7522px 94.7522px 0px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}
